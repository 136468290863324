import React from "react";
import "./style.scss";
import loadingIcon from "../../../Media/ico-loading.svg";

class PrimaryButton extends React.Component {
    refContols = null;
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    handleOnClick()
    {
        if(this.props.onButtonClick && !this.props.loading)
        {
            this.props.onButtonClick();
        }
    }

    render() {
        const title = this.props.title || "";
        const loading = this.props.loading || false;
        return (
            <div className="primary-button" onClick={() => this.handleOnClick()}>
                <div className="primary-button-text">
                    {
                        loading ? <img className="primary-button-loading" alt={"loading"} src={loadingIcon}></img> : <span>{title}</span>
                    }
                </div>
            </div>
        );
    }
}

export default PrimaryButton;
