

/**
 * Хендлер вызова переданной функции @action при клике @event вне элемента @ref 
 * @param {any} bindObj - точка вызова хендлера (по умолчанию передавать this)
 * @param {Document} body - ReactDOM, в котором происходит обработка события (по умолчанию передавать document)
 * @param {any} ref - элемент, вне которого проверяется клик
 * @param {Function} action - обработчик, который нужно вызвать при клике вне компонента @ref
 * @param {string} type - тип обрабатываемого события (по умолчанию передавать "click")
 * @param {boolean} condition - условие добавления события (при каком condition нужно добавить обработчик события на @body) 
 */
 function outsideClickHandler(options) {
    const {
        bindObj,
        body, 
        ref,
        action, 
        type, 
        condition
    } = options;
    if (condition) {
        body.addEventListener(type, (event) => handleOutsideEvent(event, ref, action.bind(bindObj)), { capture: true })
    }
    else {
        body.removeEventListener(type, (event) => handleOutsideEvent(event, ref, action.bind(bindObj)), { capture: true })
    }
}

function handleOutsideEvent(event, ref, action) {
    if (!ref || !ref.current) {
        return;
    }

    if (!ref.current.contains(event.target)) {
        action();
    }
}

function processRedirect(redirect)
{
    let resultRedirect = process.env.REACT_APP_DEFAULT_REDIRECT;

    if(!redirect)
    {
        return resultRedirect;
    }

    try {
        const url = new URL(redirect);
        resultRedirect = url.toString();
    } catch (error) {
        
    }

    return resultRedirect;
}

function getCookie(cookieName)
{
    if(!cookieName)
    {
        return null;
    }
    const cookieValue = document.cookie.split('; ').filter(row => row.startsWith(`${cookieName}=`)).map(c=>c.split('=')[1])[0];

    if(!cookieValue)
    {
        return null;
    }

    return cookieValue;
}

module.exports = {
    outsideClickHandler,
    processRedirect,
    getCookie,
}