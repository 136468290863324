import React from "react";
import "./style.scss";

import CommonInput from "../Common";

class DefaultInput extends React.Component {
    refContols = null;
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.defaultValue || "",
        };
        this.refContols = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.value !== this.state.value) {
            if (this.props.onChangeValue) {
                this.props.onChangeValue(this.state.value);
            }
        }
    }

    handleChangeValue(value) {
        this.setState({
            value: value,
        });
    }

    handleBadgeClick() {
        if (this.props.onBadgeClick) {
            this.props.onBadgeClick();
        }
    }

    render() {
        const type = this.props.type || "text";
        const badge = this.props.badge;
        const defaultValue = this.props.defaultValue || "";
        return (
            <div className="input-wrapper" id={this.props.id || "input-field"}>
                <CommonInput
                    ref={this.refContols}
                    autoComplete={this.props.autoComplete}
                    type={type}
                    placeholder={this.props.placeholder}
                    defaultValue={defaultValue}
                    disabled={this.props.disabled}
                    onChangeValue={(value) => this.handleChangeValue(value)}
                />
                {badge && (
                    <img
                        className="input-badge"
                        src={badge}
                        alt="ci"
                        onClick={(value) => this.handleBadgeClick(value)}
                    />
                )}
            </div>
        );
    }
}

export default DefaultInput;
