
import axios from "axios";
import ln from "../Localization";

const defaultResponse = () => {
    return {
        success: false,
        message: ln.tl("common.serverError")
    }
}

async function request(type, url, body, headers, additionalOptions)
{
    try {
        const options = Object.assign({
            url: url,
            method: type,
            data: body,
            headers: headers,
        }, additionalOptions);

        const res = await axios(options);
        let result = Object.assign({}, res.data);
        
        result._serviceInfo = res;
        delete result._serviceInfo.data;

        return result;
    } catch (error) {
        if (error.response) {
            if (error.response.data) {
                const body = error.response.data;
                body._serviceInfo = error.response;
                delete body._serviceInfo.data;
                return body;
            }
        }
        return defaultResponse();
    }
}

const utils = {
    request,
}

export default utils;