import React from "react";
import SignInForm from "../../Layouts/SignForms/SignIn";
import SignUpForm from "../../Layouts/SignForms/SignUp";
import Logo from "../../Media/adabstar-logo.svg";

import ln from "../../Localization";

import "./style.scss"

class SignView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            section: "sign-in",
        };
        this.refControls = React.createRef();
    }

    componentDidMount() {
        this.setState({section: this.getSection()})
    }

    componentDidUpdate() {

    }

    availableSections = [
        "sign-in",
        "sign-up",
    ];

    linkToSection(section)
    {
        let resultSection = this.availableSections[0];
        if(this.availableSections.includes(section))
        {
            resultSection = section;
        }

        const params = new URLSearchParams(document.location.search);
        params.set("section", resultSection);

        return `/auth?${params.toString()}`;
    }

    getSection()
    {
        const params = new URLSearchParams(document.location.search);
        const rawSection = params.get("section");

        let section = this.availableSections[0];
        if(this.availableSections.includes(rawSection))
        {
            section = rawSection;
        }

        return section;
    }

    render() {
        const section = this.state.section;
        return (
            <div className="sign-view-form">
                <div className="sign-view-title">
                    {
                        section === "sign-up" &&
                        <span className="sign-view-title-text">Присоединяйся к </span>
                    }
                    <img className="sign-view-title-logo" alt="Adabstar" src={Logo}/>
                </div>
                <div className="sign-view-header">
                    <a 
                        href={this.linkToSection("sign-in")}
                        className={`sign-view-header-section ${section === "sign-in" ? "active" : "inactive"}`}
                    >
                        {ln.tl("sign.in.title")}
                    </a>
                    <div>
                        {ln.tl("common.or")}
                    </div>
                    <a 
                        href={this.linkToSection("sign-up")}
                        className={`sign-view-header-section ${section === "sign-up" ? "active" : "inactive"}`}
                    >
                        {ln.tl("sign.up.title")}
                    </a>
                </div>
                {(() => {
                    switch (section) {
                        case 'sign-in':
                            return <SignInForm/>;
                        case 'sign-up':
                            return <SignUpForm/>;
                        default:
                            return <SignUpForm/>;
                    }
                })()}
            </div>
        );
    }
}

export default SignView;
