import React from "react";
import Logo from "../../Media/adabstar-logo.svg";

import ln from "../../Localization";


import { UAS } from "../../api";

import "./style.scss"
import PrimaryButton from "../../Components/Buttons/Primary";

class SignView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            success: 0,
        };
    }


    async componentDidMount() {
        const params = new URLSearchParams(document.location.search);
        const confirmSign = params.get("confirmSign") || "";

        const result = await UAS.confirmEmail(confirmSign);

        let success = 0;
        if(result && result.success === true)
        {
            success = 1;
        }
        else
        {
            success = -1;
        }
        this.setState({
            success: success,
        })
    }
    

    render() {

        return (
            <div className="main-view">
                {(() => {
                        switch(this.state.success) {
                          case 1:
                            return <div className="sign-form-inputs">
                                {ln.tl("emailConfirm.success")}
                                <br/>
                                    <PrimaryButton 
                                        title={"На главную"}
                                        loading={this.state.loading}
                                        onButtonClick={() => window.location.href = process.env.REACT_APP_DEFAULT_REDIRECT}
                                    />
                                </div>
                          case -1:
                            return <div>{ln.tl("emailConfirm.fail")}</div>
                          default:
                            return <div>Подтверждаем почту...</div>
                        }
                      })()}
            </div>
        );
    }
}

export default SignView;
