import React from "react";
import "./style.scss";

class CommonInput extends React.Component {
    refContols = null;
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.defaultValue || "",
        };
        this.refContols = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.value !== this.state.value) {
            if (this.props.onChangeValue) {
                this.props.onChangeValue(this.state.value);
            }
        }
    }

    handleChangeValue(event) {
        const r = this.props.pattern;
        if (r) {
            if (r.test(event.target.value)) {
                this.setState({
                    value: event.target.value,
                });
            }
            event.target.value = this.state.value;
        } else {
            this.setState({
                value: event.target.value,
            });
        }
    }

    render() {
        const type = this.props.type || "text";
        const defaultValue = this.props.defaultValue || "";
        return (
            <input
                ref={this.refContols}
                autoComplete={this.props.autoComplete || "off"}
                type={type}
                placeholder={this.props.placeholder}
                defaultValue={defaultValue}
                className="input"
                disabled={this.props.disabled}
                onChange={(event) => this.handleChangeValue(event)}
            />
        );
    }
}

export default CommonInput;
