import React from "react";
import DefaultInput from "../../Components/Input/Default";
// import Dropdown from "../../Components/Dropdown";
import PasswordInput from "../../Components/Input/Password";
import PrimaryButton from "../../Components/Buttons/Primary";
import {UAS} from "../../api";
import ln from "../../Localization";
import utils from "../../utils";

import "./style.scss"

class SignUpForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            region: "RU",
            email: "",
            password: "",
            loading: false,
            error: false,
            message: "",
            regions: [],
        };
        this.refControls = React.createRef();
    }

    getUsername(value) {
        this.setState({username: value})
    }

    getRegion(values) {
        this.setState({region: values[0].code})
    }

    getEmail(value) {
        this.setState({email: value})
    }

    getPassword(value) {
        this.setState({password: value})
    }

    componentDidMount() {
        UAS.getRegions().then(
            res => {
                this.setState({regions: res.regions})
            }
        );
        
    }


    componentDidUpdate() {

    }

    async signUp()
    {
        const s = this.state;
        if(s.username === "" || s.password === "" || s.email === "")
        {
            //  return;
        }

        this.setState({loading: true})
        const signUpBody = {
            region: "RU",
            username: s.username,
            email: s.email,
            password: s.password,
        }

        const res = await UAS.signUp(signUpBody);

        if(res.success === true)
        {
            this.setState({
                loading: false,
                error: false,
                message: ln.tl("sign.up.success"),
            })
            window.open(utils.processRedirect(), "_self")
        }
        else
        {
            this.setState({
                loading: false,
                error: true,
                message: res.message,
            })
        }
    }

    render() {
        const s = this.state;
        // const regions = this.state.regions;
        return (
            <div className="sign-form">
                <form className="sign-form-inputs">
                    <div className="sign-form-input-container">
                        <div className={`sign-form-message-field ${s.error ? "error" : "success"}`}>
                            {s.message}
                        </div>
                    </div>
                    <div className="sign-form-input-container">
                        <DefaultInput
                            id={"username"}
                            placeholder={ln.tl("sign.common.usernamePlaceholder")}
                            onChangeValue={(value) => this.getUsername(value)}
                            autoComplete={"off"}
                        />
                    </div>
                    {/* <div className="sign-form-input-container">
                        {
                            regions.length > 0 && <Dropdown
                            placeholder={ln.tl("sign.common.regionPlaceholder")}
                            values={regions}
                            onChange={(values) => this.getRegion(values)}
                        />
                        }
                    </div> */}
                    <div className="sign-form-input-container">
                        <DefaultInput
                            id={"email"}
                            placeholder={ln.tl("sign.common.emailPlaceholder")}
                            onChangeValue={(value) => this.getEmail(value)}
                            autoComplete={"off"}
                        />
                    </div>
                    <div className="sign-form-input-container">
                        <PasswordInput
                            onChangeValue={(value) => this.getPassword(value)}
                            autoComplete={"new-password"}
                        />
                    </div>
                    <div className="sign-form-input-container">
                        {/* TODO: Подумать над тем, как лучше сделать генерацию текста для политики и пользовательского соглашения*/}
                        <div className="sign-form-policy-text">
                            <span>
                                {ln.tl("sign.up.policyTextPart1")}
                            </span>
                            <a href={process.env.REACT_APP_POLICY_LINK} /*TODO вставить корректную ссылку*/ className="sign-form-link">
                                <span>
                                    {ln.tl("sign.up.policyTitle")}
                                </span>
                            </a>
                            <span>
                                {" " + ln.tl("common.and") + " "}
                            </span>
                            <a href={process.env.REACT_APP_AGREEMENT_LINK} /*TODO вставить корректную ссылку*/ className="sign-form-link">
                                <span>
                                    {ln.tl("sign.up.agreementTitle")}
                                </span>
                            </a>
                            <span>
                                {ln.tl("sign.up.policyTextPart2")}
                            </span>
                        </div>
                    </div>
                    <div className="sign-form-input-container">
                        <PrimaryButton 
                            title={ln.tl("sign.up.button")}
                            loading={this.state.loading}
                            onButtonClick={() => this.signUp()}
                        />
                    </div>
                </form>
            </div>
        );
    }
}

export default SignUpForm;
