//#region Locales Import
const ru = require("./Locales/ru_RU.json");

//#endregion

const DEFAULT_LOCALE = "ru_RU";

const locales = {
    "ru_RU": ru,
}

function getLocale()
{
    const browserLanguage = navigator.language;
    const locale = browserLanguage.replace("-", "_")
    return locale;
}

function getMessage(localeObj, path, localeCode)
{
    const elements = path.split(".");

    let accum = localeObj;
    for(const element of elements)
    {   
        accum = accum[element];

        if(accum === undefined)
        {
            accum = `Failed to load ${localeCode} locale for message ${path}`;
            break;
        }
    }

    const message = typeof accum === "string" ? accum : JSON.stringify(accum);

    return message;
}

function tl(path)
{
    const locale = getLocale();
    let localeObj = locales[locale];

    if(!localeObj)
    {
        // return `Failed to load locale for message ${path}`;
        localeObj = locales[DEFAULT_LOCALE];


    }

    return getMessage(localeObj, path, locale);
}


const ln = {
    getLocale,
    tl,
}

export default ln;