import utils from "./utils";

async function signIn(signInBody, redirect = "") {
    const requestOptions = {
        withCredentials: true
    };

    const result = await utils.request("post", `${process.env.REACT_APP_UAS_HOST}/uas/users/sign-in?redirect=${redirect}`, signInBody, {}, requestOptions);
    return result;
}

async function signUp(signUpBody) {
    const requestOptions = {
        withCredentials: true
    };

    const result = await utils.request("post", `${process.env.REACT_APP_UAS_HOST}/uas/users/sign-up`, signUpBody, {}, requestOptions);
    return result;
}

async function signOut() {
    const requestOptions = {
        withCredentials: true
    };

    const result = await utils.request("post", `${process.env.REACT_APP_UAS_HOST}/uas/users/sign-out`, {}, {}, requestOptions);
    return result;
}

async function getRegions() {
    // const result = await axios.post("http://localhost:9901/uas/users/sign-in", signInBody);
    // const requestOptions = {
    // withCredentials: true
    // };

    // const result = await axios.post(`http://localhost:9901/uas/users/sign-in?redirect=${redirect}`, signInBody, requestOptions);
    // // const result = await axios.get(`${process.env.REACT_APP_UAS_HOST}/uas/users/sign-up`, requestOptions);

    const result = {
        total: 3,
        regions: [{
                id: 1,
                name: "Россия",
                code: "RU",
            },
            {
                id: 2,
                name: "Евросоюз",
                code: "EU",
            },
            {
                id: 3,
                name: "Турция",
                code: "TR",
            },
        ]
    }

    return result;
}

async function checkAuth() {
    const requestOptions = {
        withCredentials: true
    };

    const result = await utils.request("get", `${process.env.REACT_APP_UAS_HOST}/uas/users/sign-check`, {}, {}, requestOptions);
    return result;
}

async function confirmEmail(confirmSign) {
    const requestOptions = {
        // withCredentials: true
    };

    const result = await utils.request("get", `${process.env.REACT_APP_UAS_HOST}/uas/users/email-confirm?confirmSign=${confirmSign}`, {}, {}, requestOptions);
    return result;
}

const UMSApi = {
    signIn,
    signUp,
    signOut,
    getRegions,
    checkAuth,
    confirmEmail,
}

export default UMSApi;