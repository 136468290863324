
import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainView from "./Views/Main";
import SignView from "./Views/Sign";
import SignOutView from "./Views/SignOut";
import EmailConfirmView from "./Views/EmailConfirm";

class App extends React.Component {
    render() {
        return (
            <div>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<MainView/>} />
                        <Route path="/auth" element={<SignView/>} />
                        <Route path="/sign-out" element={<SignOutView/>} />
                        <Route path="/email-confirm" element={<EmailConfirmView/>} />
                    </Routes>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
