import React from "react";
import DefaultInput from "../Default";
import eyeBadge from "../../../Media/ico-eye.svg"

class PasswordInput extends React.Component {
    refContols = null;
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.defaultValue || "",
            revealed: false,
        };
        this.refContols = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.value !== this.state.value) {
            if (this.props.onChangeValue) {
                this.props.onChangeValue(this.state.value);
            }
        }
    }

    handleChangeValue(value) {
        this.setState({
            value: value,
        });
    }

    handleBadgeClick() {
        const revealed = this.state.revealed;
        this.setState({
            revealed: !revealed,
        });
    }

    render() {
        return (
            <DefaultInput
                id="password"
                type={this.state.revealed ? "text" : "password"}
                placeholder="Пароль"
                autoComplete={this.props.autoComplete}
                badge={eyeBadge}
                onChangeValue={(value) => this.handleChangeValue(value)}
                onBadgeClick={() => this.handleBadgeClick()}
            />
        );
    }
}

export default PasswordInput;
