import React from "react";
import utils from "../../utils";
import Logo from "../../Media/adabstar-logo.svg"
import "./style.scss"
import { UAS } from "../../api";

class SignOutView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.refControls = React.createRef();
    }

    async componentDidMount()
    {
        const params = new URLSearchParams(document.location.search);
        const rawRedirect = params.get("redirect");

        const redirect = utils.processRedirect(rawRedirect);

        await UAS.signOut();
        
        window.open(`${redirect}`, "_self");
    }

    render() {
        return (
            <div className="main-view">
                <img alt="Adabstar Logo" src={Logo} className="main-view-logo"></img>
            </div>
        );
    }
}

export default SignOutView;
