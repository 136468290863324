import React from "react";
import utils from "../../utils";
import Logo from "../../Media/adabstar-logo.svg"
import "./style.scss"
import { UAS } from "../../api";

class MainView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.refControls = React.createRef();
    }

    async componentDidMount()
    {
        const params = new URLSearchParams(document.location.search);
        const rawRedirect = params.get("redirect");
        const redirect = utils.processRedirect(rawRedirect);

        const result = await UAS.checkAuth();
        
        if(result.success !== true)
        {
            window.open(`/auth?section=sign-in&redirect=${redirect}`, "_self");
            // window.open(`/auth?section=sign-in&redirect=${redirect}`);
        }
        else
        {
            window.open(`${redirect}`, "_self");
            // window.open(`${redirect}`);
        }
    }

    render() {
        return (
            <div className="main-view">
                <img alt="Adabstar Logo" src={Logo} className="main-view-logo"></img>
            </div>
        );
    }
}

export default MainView;
