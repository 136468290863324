import React from "react";
import DefaultInput from "../../Components/Input/Default";
import PasswordInput from "../../Components/Input/Password";
import PrimaryButton from "../../Components/Buttons/Primary";
import { UAS } from "../../api";
import ln from "../../Localization";
import utils from "../../utils";

import "./style.scss"

class SignInForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            login: "",
            password: "",
            loading: false,
            error: false,
            message: "",
        };
        this.refControls = React.createRef();
    }

    getLogin(value) {
        this.setState({login: value})
    }

    getPassword(value) {
        this.setState({password: value})
    }

    async componentDidMount() {
        const result = await UAS.checkAuth();

        if(result.success === true)
        {
            const params = new URLSearchParams(document.location.search);
            const redirect = params.get("redirect") || "";
            window.open(utils.processRedirect(redirect), "_self")
        }
    }

    componentDidUpdate() {

    }

    async signIn()
    {
        const s = this.state;
        if(s.login === "" || s.password === "")
        {
             return;
        }

        this.setState({loading: true})
        const signInBody = {
            login: s.login,
            password: s.password,
        }
        const params = new URLSearchParams(document.location.search);
        const redirect = params.get("redirect") || "";
        const res = await UAS.signIn(signInBody, redirect);

        if(res.success === true)
        {
            this.setState({
                loading: false,
                error: false,
                message: ln.tl("sign.in.success"),
            })
            window.open(utils.processRedirect(redirect), "_self")
        }
        else
        {
            this.setState({
                loading: false,
                error: true,
                message: res.message,
            })
        }
    }

    render() {
        const s = this.state;
        return (
            <div className="sign-form">
                <form className="sign-form-inputs">
                    <div className="sign-form-input-container">
                        <div className={`sign-form-message-field ${s.error ? "error" : "success"}`}>
                            {s.message}
                        </div>
                    </div>
                    <div className="sign-form-input-container">
                        <DefaultInput
                            id={"login"}
                            placeholder={ln.tl("sign.in.loginPlaceholder")}
                            onChangeValue={(value) => this.getLogin(value)}
                        />
                    </div>
                    <div className="sign-form-input-container">
                        <PasswordInput
                            onChangeValue={(value) => this.getPassword(value)}
                        />
                    </div>
                    {/* <div className="sign-form-input-container">
                        <a href="/" className="sign-form-link">
                            {ln.tl("sign.in.forgotPassword")}
                        </a>
                    </div> */}
                    <div className="sign-form-input-container">
                        <PrimaryButton 
                            title={ln.tl("sign.in.button")}
                            loading={this.state.loading}
                            onButtonClick={() => this.signIn()}
                        />
                    </div>
                </form>
            </div>
        );
    }
}

export default SignInForm;
